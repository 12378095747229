import { Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-extenders-galaxy',
  templateUrl: './galaxy-setup.component.html',
})
export class GalaxySetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  doorFilterInOptions: any[] = [];
  doorFilterOutOptions: any[] = [];
  selectedDoorFilterInOption: any;
  selectedDoorFilterOutOption: any;

  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}

  ngOnInit(): void {
    this.loadInitialData();
  }

  loadInitialData(): void {
}

  // doorFilterInOptionChanged(): void {
  //   if (this.selectedDoorFilterInOption) {
  //     this.setupState.galaxyDoorFilterIn.push(this.selectedDoorFilterInOption.id);
  //     this.selectedDoorFilterInOption = null;
  //   }
  // }

  // doorFilterOutOptionChanged(): void {
  //   if (this.selectedDoorFilterOutOption) {
  //     this.setupState.galaxyDoorFilterOut.push(this.selectedDoorFilterOutOption.id);
  //     this.selectedDoorFilterOutOption = null;
  //   }
  // }

  // removeDoorFilterIn(filter: number): void {
  //   this.setupState.galaxyDoorFilterIn = this.setupState.galaxyDoorFilterIn.filter(f => f !== filter);
  // }

  // removeDoorFilterOut(filter: number): void {
  //   this.setupState.galaxyDoorFilterOut = this.setupState.galaxyDoorFilterOut.filter(f => f !== filter);
  // }

  canGoNext(): boolean {
    return true;
  }
}