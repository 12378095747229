<div class="c-card__section">
  <h3>Galaxy settings</h3>
  
  <!-- Card ID Field -->
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Card ID</label>
      </div>
      <div class="l-bar__item-end">
        <input type="number" [(ngModel)]="setupState.galaxyCardId" class="c-input" />
      </div>
    </div>
  </div>

  <!-- Door Filter In Field -->
  <!-- <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Door Filter In</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="selectedDoorFilterInOption"
          [options]="doorFilterInOptions"
          (ngModelChange)="doorFilterInOptionChanged()"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
      </div>
    </div>
  </div> -->

  <!-- Door Filter Out Field -->
  <!-- <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Door Filter Out</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="selectedDoorFilterOutOption"
          [options]="doorFilterOutOptions"
          (ngModelChange)="doorFilterOutOptionChanged()"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
      </div>
    </div>
  </div> -->

  <!-- Door Filter In List -->
  <!-- <div class="l-grid" *ngIf="setupState.doorFilterIn.length > 0">
    <div class="l-grid__cell l-grid__cell--12">
      <div class="c-stacked-list c-stacked-list--s">
        <ul class="c-stacked-list__list">
          <li *ngFor="let filter of setupState.doorFilterIn" class="c-stacked-list__item">
            <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removeDoorFilterIn(filter)">
              <div class="c-stacked-list__inner-start">
                Door Filter In: <code>{{ filter }}</code>
              </div>
              <div class="c-stacked-list__inner-end">
                <span class="c-icon c-icon--m">
                  <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->

  <!-- Door Filter Out List -->
  <!-- <div class="l-grid" *ngIf="setupState.doorFilterOut.length > 0">
    <div class="l-grid__cell l-grid__cell--12">
      <div class="c-stacked-list c-stacked-list--s">
        <ul class="c-stacked-list__list">
          <li *ngFor="let filter of setupState.doorFilterOut" class="c-stacked-list__item">
            <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removeDoorFilterOut(filter)">
              <div class="c-stacked-list__inner-start">
                Door Filter Out: <code>{{ filter }}</code>
              </div>
              <div class="c-stacked-list__inner-end">
                <span class="c-icon c-icon--m">
                  <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->

  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>